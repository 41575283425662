.initial-swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.initial-swiper .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 30%;
}

.initial-swiper .swiper-slide img {
    display: block;
    width: 100%;
}

.initial-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.initial-container h1{
    margin: 0;
    color: white;
    text-shadow: 3px 4px black;
}