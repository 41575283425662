/* Add this CSS to your stylesheet (e.g., App.css) */
@font-face {
  font-family: 'lora';
  src: url('/public/fonts/Lora/Lora-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.demoPage {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  /* Dark text color for readability */
  background-color: #f8f8f8;
  /* Light background color */
  font-family: 'Times New Roman', serif;
  /* Classic book-style font */
  font-size: 18px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  /* Soft shadow for a page-like effect */
  border: 1px solid #ddd;
  overflow: hidden;
  font-family: 'lora';
  width: 100vw;  /* Full viewport width */
  height: 100vh; 
}

.firstPage {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.book-cover {
  background-color: brown;
  border: none;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
}

.demoPage {
  color: #D0930A;
}

.page-header {
  height: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #85807C;
}

.demoPage .page-layout {
  width: 90%;
  height: 90%;
  margin-left: 5%;
  border: 1px solid #D0930A;
}

.page-inner {
  width: 90%;
  height: 90%;
  margin-top: 5%;
  margin-left: 5%;
  text-align: justify;
  font-size: 15px;
}

.page-inner img {
  width: 100%;
  height: 200px;
}

.page-hr {
  border-top: 0.5px solid #D0930A;
}

.page-topic {
  color: black;
}

.page-footer {
  height: 5%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #85807C;
}