.game-background {
  height: 100vh;
  width: 100vw;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  position: relative;
  overflow: hidden;
}

.game-ongoing-container {
  position: absolute;
  width: 80vw;
  background-color: rgba(255, 255, 255, 0.9);
  height: 80vh;
  left: 10vw;
  top: 10vh;
  border-radius: 10px;
}

.library-ongoing-container {
  position: absolute;
  width: 80vw;
  background-color: transparent;
  height: 80vh;
  left: 10vw;
  top: 10vh;
}