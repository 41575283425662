.ongoing-place-game-layout {
    width: 90%;
    height: 90%;
    position: absolute;
    top: 5%;
    left: 5%;
}

.ongoing-place-game-layout .ant-row {
    width: 100%;
    height: 100%;
}

.game-left {
    width: 100%;
    height: 100%;
    padding: 0;
}

.game-right {
    width: 100%;
    height: 100%;
}

.game-header {
    color: #fff;
    height: 10%;
    padding-inline: 3em;
    /* Adjust this value based on design */
    line-height: 64px;
    background-color: transparent;
}

@media (max-width: 600px) {
    .game-header {
        padding-inline: 1em;
    }
}

.game-content {
    height: 80%;
    color: #fff;
    background-color: '#0958d9';
}

.game-submit {
    color: #fff;
    background-color: transparent;
    height: 10%;
}

.game-question {
    display: flex;
    align-items: center;
    margin: 0;
    color: black;
    height: 20%;
    padding-left: 3em;
    padding-top: 2%;
}

@media (max-width: 768px) {
    .game-question {
        font-size: 1.2em;
        padding-inline: 5%;
    }
}

.game-answer {
    height: 80%;
    padding-inline: 48px;
}

.game-answer ul {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.game-answer ul li {
    display: flex;
    height: 18%;
    align-items: center;
    margin-bottom: 2.5%;
}

@media (max-width: 768px) {
    .game-answer ul li {
        font-size: 1em;
    }
}

.answer-option {
    border-style: solid;
    border-radius: 10px;
    width: 100%;
    height: 90%;
    display: flex;
    align-items: center;
    padding-inline-start: 30px;
    padding-inline-end: 30px;
    color: black;
}

.game-submit {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 10%;
    padding: 1%;
}

@media (max-width: 768px) {
    .game-submit {
        justify-content: center;
    }
}

.summary-content {
    height: 60%;
}

.summary-content img {
    height: 40%;

}

.summary-content h1 {
    margin: 0;
}

.summary-content h2 {
    margin: 0;
}

.summary-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

@media (max-width: 768px) {

    .summary-content h1,
    .summary-content h2 {
        font-size: smaller;
    }

    .summary-footer .reactive-button {
        width: 60%;
        height: 60%;
    }
}