.menu-col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-col button {
  width: 200px;
  height: 200px;
}

.menu-col img {
  width: 50%;
  height: 50%;
}

.menu-container {
  margin: 0;
  font-size: 2em;
  color: white;
  text-shadow: 3px 4px black;
}